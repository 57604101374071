<template>

    <el-row :gutter="20" :disabled="showindex">
        <el-col :span="8">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <div>服务器数量</div>
                    <div class="text item">
                        {{servernum}}
                    </div>
                </el-card>
            </div>
        </el-col>
        <el-col :span="8">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <div>用户总数</div>
                    <div class="text item">
                        {{playernum}}
                    </div>
                </el-card>
            </div>
        </el-col>
        <el-col :span="8">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <div>在线用户</div>
                    <div class="text item">
                        {{onlinenum}}
                    </div>
                </el-card>
            </div>
        </el-col>
        <el-col :span="8">
            <div class="grid-content bg-purple">
                <el-card class="box-card">
                    <div>今日活跃</div>
                    <div class="text item">
                        {{todayactive}}
                    </div>
                </el-card>
            </div>
        </el-col>        
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    
    export default {
        data() {
            return {
                showindex: false,
                servernum: 0,
                onlinenum: 0,
                playernum: 0,
                todayactive: 0,
            };
        },
        created: function() {
            this.refreshInfo();
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
        },
        methods: {
            // 刷新主页信息
            refreshInfo() {
                this.gameHttp(1201, {}).then((response) => {
                    this.servernum = response.data.servernum;
                    this.onlinenum = response.data.onlinenum;
                    this.playernum = response.data.playernum;
                    this.todayactive = response.data.todayactive;
                });
            },
        },
    }
</script>

<style>
    .text {
        font-size: 36px;
        font-weight: bold;
    }

    .item {
        padding: 64px;
    }

    .box-card {
        margin-top: 36px;
        width: 400px;
    }
    
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
    text-align: center;
  }
  .bg-purple {
    padding-left: 30px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
